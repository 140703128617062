import styled from 'styled-components';

interface disableOverflowHidden {
	disableOverflowHidden?: boolean;
}

export const ScPage = styled.div<disableOverflowHidden>`
	display: flex;
	flex-direction: column;
	max-width: 100vw;
	overflow: ${({ disableOverflowHidden }) => (disableOverflowHidden ? 'initial' : 'hidden')};
`;
