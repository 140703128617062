import React from 'react';
import { Link } from 'gatsby';

import { ScContainer } from '../../components/container/styled';

import { ScAboutUsHeroTextColumns, ScTellHeroWrapper, ScUpdateWrapper } from './styled';

export const ParentsUpdate = () => {
	return (
		<ScTellHeroWrapper>
			<ScContainer>
				<ScUpdateWrapper>
					<h3>Update your website/Send an email</h3>
					<p>
						Here's some useful copy about Gogeta Nursery for you to update your website or send an
						email to parents:
					</p>
					<ScAboutUsHeroTextColumns>
						<div>
							<p>
								We are signed up to Gogeta Nursery, an employee benefit scheme that lets you make
								big savings on your nursery fees by paying via salary sacrifice.
							</p>
							<p>
								<b>How much can I save?</b>
							</p>
							<p>
								You can save between 19-40% per year on your nursery bill (depending on your tax
								band) by paying out of your gross salary, before tax and NI contributions. Unlike
								the Tax Free Childcare scheme (which saves you 20% but caps savings at £2000 per
								year) savings with Gogeta are uncapped, so savings are almost always greater with
								Gogeta Nursery.
							</p>
							<p>
								<b>Can I use Gogeta Nursery with Government funded hours?</b>
							</p>
							<p>
								Yes, you can use Gogeta Nursery in conjunction with the Government’s funded hours
								scheme. Simply salary sacrifice your nursery costs (after the funded hours have been
								deducted) and make big savings on the remainder of your fees.
							</p>
						</div>
						<div>
							<p>
								<b>The nursery benefits too</b>
							</p>
							<p>
								As part of the workplace nursery scheme, for every parent who pays their fees using
								Gogeta Nursery, we'll receive additional funding from the employer, which is 12.5%
								of your fees. This doesn’t cost you anything and it is covered by the national
								insurance savings your employer makes, so it is cost neutral to them too. We agree
								with you how to use the funding, where the nursery needs it most.
							</p>
							<p>
								<b>How do I apply?</b>
							</p>
							<p>
								For you to use Gogeta Nursery your employer will need to sign up. It’s simple, quick
								and offering the Gogeta Nursery benefit to staff doesn’t cost your employer a penny.
								Find out more by visiting the{' '}
								<Link to="/employer-nursery/">
									<u>Gogeta Nursery website</u>
								</Link>
								.
							</p>
						</div>
					</ScAboutUsHeroTextColumns>
				</ScUpdateWrapper>
			</ScContainer>
		</ScTellHeroWrapper>
	);
};
