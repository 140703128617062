import React from 'react';
import { useTheme } from 'styled-components';

import file3 from '../../assets/marketing-materials/Banner.png';
import fileCustomer3 from '../../assets/marketing-materials/Banner-tell.png';
import file2 from '../../assets/marketing-materials/Gogeta Bike - Retailer - Flyer (A5) .pdf';
import file1 from '../../assets/marketing-materials/Gogeta Bike - Retailer - Poster (A3) .pdf';
import fileCustomer2 from '../../assets/marketing-materials/Gogeta Nursery - Nursery - Flyer (A5)-tell.pdf';
import fileCustomer1 from '../../assets/marketing-materials/Gogeta Nursery - Nursery - Poster-tell.pdf';
import { ScContainer } from '../../components/container/styled';
import { CustomButton } from '../../components/custom-button';
import { download } from '../../utils/download';

import {
	ScTellDownloadTextWrapper,
	ScTellDownloadWrapper,
	ScTellHeroWrapper,
	ScTellPeachDownloadItemWrapper,
	ScTellPeachDownloadRowWrapper,
	ScTellPeachDownloadWrapper,
} from './styled';

export const DownloadMarketingMaterials = ({ isCustomer }: { isCustomer?: boolean }) => {
	const theme = useTheme();
	return (
		<ScTellHeroWrapper>
			<ScContainer>
				<ScTellDownloadWrapper>
					<ScTellPeachDownloadWrapper>
						<ScTellPeachDownloadRowWrapper>
							<ScTellPeachDownloadItemWrapper>
								<h3>Poster</h3>
								<CustomButton
									color={theme.colors.darkText}
									background={theme.colors.transparent}
									isArrow
									onClick={() => {
										download(isCustomer ? (file1 as string) : (fileCustomer1 as string));
									}}
								>
									Download
								</CustomButton>
							</ScTellPeachDownloadItemWrapper>
							<ScTellPeachDownloadItemWrapper>
								<h3>Guide</h3>
								<CustomButton
									color={theme.colors.darkText}
									background={theme.colors.transparent}
									isArrow
									onClick={() => {
										download(isCustomer ? (file2 as string) : (fileCustomer2 as string));
									}}
								>
									Download
								</CustomButton>
							</ScTellPeachDownloadItemWrapper>
						</ScTellPeachDownloadRowWrapper>
						<ScTellPeachDownloadItemWrapper>
							<h3>Social post</h3>
							<CustomButton
								color={theme.colors.darkText}
								background={theme.colors.transparent}
								isArrow
								onClick={() => {
									download(isCustomer ? (file3 as string) : (fileCustomer3 as string));
								}}
							>
								Download
							</CustomButton>
						</ScTellPeachDownloadItemWrapper>
					</ScTellPeachDownloadWrapper>
					<ScTellDownloadTextWrapper>
						<h3>Download or order marketing materials</h3>
						<p>
							{isCustomer
								? 'Download marketing materials to use in your stores, in customer comms or social channels below.'
								: 'Download marketing materials to give to parents or share on any parent communication app you use (like Famly).'}
						</p>

						{isCustomer ? (
							<span>
								If you'd like to order hard copies of these marketing materials for your store
								please get in touch:{' '}
								<b>
									<a href="mailto:hello@gogeta.com" target="_blank" rel="noreferrer">
										hello@gogeta.com
									</a>
								</b>
							</span>
						) : (
							<span>
								If you'd like to order hard copies of these marketing materials for your nursery
								settings please get in touch:{' '}
								<a href="mailto:hello@gogeta.com" target="_blank" rel="noreferrer">
									hello@gogeta.com
								</a>
							</span>
						)}
					</ScTellDownloadTextWrapper>
				</ScTellDownloadWrapper>
			</ScContainer>
		</ScTellHeroWrapper>
	);
};
